import React from "react";

export function CardComp({ src, alt , cardImg }) {
  return (
    <>
      <img
        src={src}
        className={`homeImg ${cardImg}`} 
        alt={alt}
        width="640"
        height="360"
        loading="lazy"
        decoding="async"
      
      />
    </>
  );
}


// import React from "react";

// export function CardComp({ src, alt, cardImg }) {
//   const generateSrcSet = (imagePath, widths) =>
//     widths.map((width) => `${imagePath}?width=${width} ${width}w`).join(", ");

//   return (
//     <img
//       src={src}
//       srcSet={generateSrcSet(src, [320, 480, 640, 960, 1280])} // Generate srcSet for responsive images
//       sizes="(max-width: 640px) 100vw, 640px" // Set sizes for responsive images
//       className={`homeImg ${cardImg}`} 
//       alt={alt || "Image description"} // Default alt text for accessibility
//       width="640"
//       height="360"
//       loading="lazy"
//       decoding="async"
//       style={{ objectFit: "cover", display: "block" }} // Prevents CLS and maintains aspect ratio
//     />
//   );
// }
